li{
    margin: 5px;
    color: white;
    font-size: 12px;
    font-family: "Inter";
}

img.coins {
    position: absolute;
    right: 40px;
    top: -55px;
}

/* *{
    color: white;
} */