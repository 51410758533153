*{
    color: white;
}
.page{
    background-color: #040404;
}
.centered-div {
    height: 63vh;
    place-content: center;
    display: grid;
}

.arranged {
    height: 500px;
    place-content: center;
    display: grid;
    margin: 0px 156px;
}

.what-crypto {
    height: auto;
    /* place-content: center; */
    display: grid;
    margin: 100px 156px 40px;
}

p.what {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 66px;
}

p.content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 25px;
    /* margin: -15px 156px; */
}

span.crypto {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 55px;
    line-height: 66px;
    align-items: center;
}

img.all-coins {
    position: absolute;
    top: -60px;
    left: -8px;
}

img.symbols {
    position: absolute;
    top: -75px;
    right: -45px;
}

p.usd {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 38px;
}

.my-margin {
    margin-top: 4rem !important;
    margin-bottom: 6rem !important;
}

.buy-button {
    /* background-color: black; */
    color: white;
    border: 0;
    border-radius: 0%;
    padding: 11px 90px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

.dollar{
    background-color:#1D6F46 ;
}

.dollar:hover{
    background-color:#005F30 ;
}

.img-links:hover{
    color: #0294FE !important;
    font-weight: bold;
}

.buy-btc{
    background-color: #040404;
    width: 229px;
    height: 33px;
    color: white;
    border: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}


.card-body, .buy_div{
    background-color: #040404;
    color: white;

}

.card-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: white !important;
}
.h5{
    color: white;
}

.card-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: white;
}

.btc-black{
    background-color: #232323;
}

.btc-black:hover{
    background-color: #808080;
}

.btc-purple{
    background-color: #6700A6;
}

.btc-purple:hover{
    background-color: #44006D;
}


.btc-blue{
    background-color: #004DCD;
}

.btc-blue:hover{
    background-color: #003387;
}

.btc-green{
    background-color: #00A151;
}

.btc-green:hover{
    background-color: #005F30;
}

.btc-pink{
    background-color: #FF00B8;
}

.btc-pink:hover{
    background-color: #DC009E;
}

.btc-orange{
    background-color: #FF5C00;
}

.btc-orange:hover{
    background-color: #FF0F00;
}


.enlarge:hover .icon-btc{
    height: 70px;
    width:70px
}

.enlarge .icon-usd:hover{
    height: 85px;
    width:85px
}

/* .format-text{
    margin: 0px 150px;
} */